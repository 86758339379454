@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
	height: 100%;
	width: 100%;
	font-family: "Source Sans Pro", sans-serif;
}
.app {
	display: flex;
	position: relative;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
	background: #888;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}
